.loading-spinner__container {
  // border: 10px solid teal;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-spinner div {
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.loading-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $--c-black;
  margin: -4px 0 0 -4px;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.036s;
}

.loading-spinner div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.072s;
}

.loading-spinner div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.108s;
}

.loading-spinner div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.loading-spinner div:nth-child(4) {
  animation-delay: -0.144s;
}

.loading-spinner div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.loading-spinner div:nth-child(5) {
  animation-delay: -0.18s;
}

.loading-spinner div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.loading-spinner div:nth-child(6) {
  animation-delay: -0.216s;
}

.loading-spinner div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.loading-spinner div:nth-child(7) {
  animation-delay: -0.252s;
}

.loading-spinner div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.loading-spinner div:nth-child(8) {
  animation-delay: -0.288s;
}

.loading-spinner div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
