.terms__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;

  .terms__section {
    // border-radius: 6px;
    // border: 1px solid rgba(0, 0, 0, 0.16);
    // padding: 0 20px;
  }

  h3 {
    font-weight: 400;
  }

  li {
    font-weight: 300;
    margin: 0 0 10px 0;
    padding: 0 0 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .terms__container {
    padding: 100px 20px;
  }
}
