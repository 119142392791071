.cookies__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0px;
  bottom: 0px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  height: auto;
  margin: 4px;
  padding: 14px 26px;
  border-radius: 6px;
  font-weight: 100;
  gap: 14px;
  font-size: $--font-sm;
  z-index: 5;

  span {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 100;
  }
}

.cookies__content {
  display: flex;
  flex-direction: row;
  justify-items: space-around;
  gap: 10px;

  button {
    padding: 6px;
    width: 160px;
    height: 22px;
    font-size: $--font-sm;
    font-weight: 200;
    outline: $--border-focus;
  }

  button:hover {
    outline: rgb(46, 46, 46) !important;
  }

  .cookies__button--more {
    height: 22px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .cookies__button--all:hover {
    color: black;
  }

  .cookies__button--decline {
    background-color: rgba(122, 122, 122, 0.3) !important;
  }
}

.cookies-popup__close {
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .cookies__container {
    width: 100%;
    margin: 0px;
    border-radius: 0;

    button {
      width: 100%;
    }
  }

  .cookies__content {
    flex-direction: column;
  }
}
