.cart__container {
  padding: 180px 100px 200px;
  background-color: $--c-white;
  font-weight: 300;
  min-height: 70vh;
}

.cart__return {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 12px;

  h1 {
    font-weight: 100;
  }
}

.cart__sections {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.cart__images {
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  padding: 30px;
  gap: 20px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  transition: all 0.3s;

  .cart__image__labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 0 0 20px;
    text-align: center;

    .cart__image__labels--photo {
      width: 200px;
    }

    .cart__image__labels--preview {
      flex: 1;
    }

    .cart__image__labels--price {
      flex: 1;
    }

    .cart__image__labels--options {
      flex: 1;
    }
  }

  .cart__image {
    display: flex;
  }

  .cart__image__tools--image {
    width: 200px;
    border-radius: 4px;
  }

  .cart__image__tools--preview {
    width: 24px;
  }

  .cart__image__tools {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
}

.cart__summary {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  gap: 20px;
  width: 300px;
  height: 300px;
  border-radius: 6px;
  border: $--border-standard;
  transition: all 0.2s;

  .cart__summary__price {
    font-size: $--font-md;
  }

  .cart__summary__button {
    width: 220px;
  }
}

.cart__images:hover,
.cart__summary:hover {
  border: $--border-focus;
}

//cart info hover text
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  opacity: 0;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.3s;
  font-size: $--font-sm;

  position: absolute;
  top: 20px;
  left: -60px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  opacity: 1;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (max-width: 600px) {
  .cart__container {
    .cart__image {
      display: flex;
      flex-direction: column;
    }

    .cart__image__tools {
      margin-top: 6px;
      justify-content: space-between;
    }

    .cart__image__tools--image {
      width: auto;
      border-radius: 4px;
    }

    .cart__image__labels {
      display: none;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .cart__image__labels--preview {
    display: none;
  }

  .cart__images {
    .cart__image__tools--preview {
      display: none;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .cart__container {
    padding: 100px 40px 100px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .cart__sections {
    display: flex;
    flex-direction: column;
  }
}
