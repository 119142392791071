.alert__container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16vh;
  right: 6vw;
  font-size: $--font-md;
  font-weight: 300;
  width: auto;
  padding: 20px 50px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 6px;
  z-index: 12;
  transition: all 0.3s;
}

.alert__container--standard {
  position: absolute;
  top: 20vh;
  left: 10vw;
}

.alert__container--red {
  background: rgba(255, 190, 190, 0.8);
  border: 1px solid rgb(135, 135, 135);
}

.alert__container--green {
  background: rgba(150, 255, 134, 0.86);
  backdrop-filter: blur(3px);
  border: $--border-standard;
}

.alert__autohide {
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    border: 0;
    opacity: 0;
  }
}

@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
