.purchased__container {
  background-color: rgba(255, 255, 255, 0.85);
  color: $--c-black;
  min-height: 70vh;
  padding: 180px 100px 160px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;

  img {
    pointer-events: auto; //re-enable image copy/drag
  }
}

.purchased__image__card {
  margin: 20px 0 40px;
  display: flex;
  flex-direction: column;

  .purchased__image__card__img {
    border-radius: 6px;
    border: $--border-focus !important;
  }

  .purchased__image__card__img:hover {
    border: $--border-standard !important;
  }

  .purchased__image__card__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: flex-start;
    gap: 20px;
    padding: 10px 0;
  }
}

.purchased__error__container {
  padding: 30px 0 0;

  h1 {
    font-size: $--font-xxl;
  }
  p {
    font-size: $--font-md;
  }

  i {
    font-weight: 400;
  }
}

.purchased__error__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.purchased__icon {
  width: 50px;
}

.purchased__icon--question {
  width: 30px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .purchased__container {
    padding: 180px 80px 100px;

    .purchased__image__card__tools {
      display: flex;
      // flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .purchased__container {
    padding: 100px 20px 60px;
  }
}
