.signin__container {
  background-color: rgba(255, 255, 255, 0.85);
  color: $--c-black;
  padding: 180px 100px 200px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;

  h1 {
    font-weight: 400;
  }

  h2 {
    font-size: $--font-md;
    font-weight: 500;
    margin: 8px 0 26px;
  }
}

.signin__container--signin {
}

.signin__container--create-account {
  .signin__form {
    background-color: rgba(253, 255, 230, 0.85);
  }
}

.signin__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;
  padding: 20px 0;
  width: 400px;
  border: 1px solid rgba(0, 255, 85, 0.15);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.5);

  label {
    padding: 0 0 2px 16px;
    font-size: 16px;
  }

  input {
    all: unset;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 8px 16px;
    width: 300px;
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
  }

  .signin__form__group {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  button {
    all: unset;
    padding: 6px 26px;
    margin-top: 22px;
    background-color: rgba(165, 254, 183, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    transition: 0.2s;
  }

  button:hover {
    background-color: rgba(70, 255, 107, 1);
  }
}

.signin__form__create-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  button {
    all: unset;
    padding: 6px 26px;
    margin-top: 22px;
    background-color: rgba(165, 250, 254, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 6px;
  }
}
