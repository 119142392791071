.shop__container {
  background-color: rgb(252, 252, 252);
  color: $--c-black;
  min-height: 90vh;
  padding: 140px 100px;
  animation: fadeIn 1s;
}

.shop__steps__cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  padding: 40px 40px 40px 0;
  margin-bottom: 20px;
  justify-content: space-between;
  border-radius: 4px;
  cursor: default;
}

.shop__steps__card {
  position: relative;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 30%;
  background-color: rgb(240, 255, 241);
  border-radius: 4px;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border: $--border-standard;
  animation: fadeIn 2s;
  transition: all 0.3s;

  span {
    font-size: 50px;
    font-weight: 100;
  }

  p {
    font-size: $--font-lg;
  }

  .shop__steps__icon {
    position: absolute;
    height: 60px;
    width: 60px;
    opacity: 0.16;
    // transition: all 0.3s;
  }

  .shop__steps__icon--eye {
    top: -40px;
    right: 30px;
  }

  .shop__steps__icon--cart {
    bottom: -30px;
    right: 30px;
  }

  .shop__steps__icon--photo {
    top: -40px;
    right: 30px;
  }
}

.shop__steps__card:hover {
  border: $--border-focus;
  background-color: rgb(199, 255, 203);
}

.shop__steps__card:hover .shop__steps__icon {
  opacity: 0.9;
}

.shop__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  margin-top: 50px;
  justify-content: center;
  animation: fadeIn 2s;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px 0px;
  }

  .shop__cards__background-image {
    position: fixed;
    opacity: 0.03;
    bottom: 100px;
    right: 100px;

    img {
      animation: fadeIn 3s;
    }
  }

  .shop__cards__background-image--large {
    left: 0px;
    bottom: 0px;
  }
}

.shop__card {
  height: auto;
  opacity: 0.9;

  img {
    width: 500px;
    height: inherit;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
  }
}

.shop__card__title {
  height: auto;
  word-wrap: break-word;
}

.shop__card:hover {
  opacity: 1;
  transition: all 1s;

  img {
    transform: scale(1.025);
    transition: ease-in-out 0.3s;
  }
}

/* Small small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .shop__container {
    padding: 80px 20px !important;
  }

  .shop__steps__card {
    padding: 10px 14px;

    p {
      font-size: $--font-sm;
    }

    span {
      font-size: 30px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .shop__steps__cards {
    .shop__steps__icon {
      display: none;
    }
  }

  .shop__card {
    padding: 10px;
  }

  .shop__cards {
    .shop__cards__background-image {
      display: none;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .shop__steps__cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
  }

  .shop__steps__card {
    width: 100%;
    height: auto;
  }

  .shop__steps__icon {
    position: relative;
    height: 60px;
    width: 60px;
    opacity: 0.16;
    justify-content: flex-end;
  }

  .shop__steps__icon--eye {
    top: 26px !important;
    right: 30px !important;
  }

  .shop__steps__icon--cart {
    top: 26px !important;
    right: 30px !important;
  }

  .shop__steps__icon--photo {
    top: 26px !important;
    right: 30px !important;
  }
}
