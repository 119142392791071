.checkout__container {
  background-color: $--c-white;
  color: $--c-black;
  min-height: 70vh;
  padding: 180px 100px 200px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
}

.checkout__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0 0;

  .checkout__items__image {
    max-width: 200px;
    align-self: center;

    img {
      object-fit: contain;
      max-height: 120px;
      border-radius: 4px;
    }
  }
}

.checkout__summary {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  margin: 0 0 20px;
  border: $--border-standard;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  transition: $--transition-standard;
}

.checkout__summary:hover {
  border: $--border-focus;
}

.checkout__summary__cart {
  // border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 6px;
}

.checkout__total {
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 200px;
  padding: 14px;
  border-radius: 6px;
  border: $--border-standard;

  span {
    font-size: $--font-md;
  }

  .checkout__total__price {
    font-size: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .checkout__container {
    padding: 120px 20px 100px;}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {

}
