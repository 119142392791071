.order-form__container {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  max-height: auto;
  border-radius: 6px;
  border: $--border-standard;
  transition: $--transition-standard;
}

.order-form__container:hover {
  border: $--border-focus;
}

.order-form__content {
  margin: 10px 0 0;

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    display: flex;
    flex-direction: column;
    margin: 6px 0;
  }

  input {
    // margin: 10px 0px;
    width: 200px;
  }

  .order-form__content__group {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .order-form__content__checkbox {
    display: flex;
    flex-direction: row;
    gap: 6px;
    // width: 10px;
    // align-items: center;

    label {
      display: block;
      width: 95%;
      a {
        color: rgb(200, 0, 0);
      }
    }

    input {
      width: 200px;
    }

    input[type='checkbox'] {
      width: 16px;
    }
  }
}

.order-form__content--error {
  background-color: pink;
  border: 1px solid grey;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  form {
    .btn--primary {
      width: auto;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .order-form__content {
    .order-form__content__group {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
  }

  .order-form__content__checkbox {
    input[type='checkbox'] {
      width: 24px !important;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}
