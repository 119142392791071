.section__welcome {
  animation: 3s ease-out 0s 1 fadeIn;
  z-index: 1;
  display: flex;
  flex-direction: row;
  animation: fadeIn 5s;
}

.cover__icons {
  position: fixed;
  bottom: 12vh;
  right: 5vw;
  z-index: 5;

  .cover__icons__row-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));

    .cover__icons__social {
      display: flex;
      flex-direction: row;
      gap: 10px;
      img {
        height: 40px;
      }
    }
  }
}

.cover__image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.cover__icons__nav--left,
.cover__icons__nav--right {
  opacity: 0.7;
}

.control-arrow {
  width: 80px;
  opacity: 1 !important;
}

.control-dots li {
  .dot {
    height: 10px;
    border: 3px solid white !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
