.section__photos {
  padding: 0 0 1px 0;
  margin: 0;
  background-color: $--c-white;
  animation: fadeIn 1s;
}

.section__photos__images img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.section__photos > .section__photos__images:first-child {
  margin-bottom: 60px;
}

.section__photos > .section__photos__images:not(:first-child) {
  //all images except the first in album
  margin: 0 150px 100px;
  height: auto;
  width: auto;

  img {
    height: 80vh;
    object-fit: contain;
  }
}

.section__photos__images__image--vertical {
  height: 100%;
  width: auto;
  object-fit: scale-down !important;
}

.section__photos__description {
  opacity: 0;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 50vw;
  margin: 0 auto;
  bottom: 50px;
  text-align: center;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.4);
  color: $--c-white;
  backdrop-filter: blur(4px);
  padding: 20px;
}

.section__photos__images__image:hover .section__photos__description {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.section__photos__see-more {
  position: fixed;
  bottom: 12vh;
  right: 10vw;
  opacity: 1;

  .section__photos__see-more__container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .section__photos__see-more__icon img {
    height: 60px;
    transform: rotate(180deg);
  }
}

.section__photos__see-more--hidden {
  opacity: 0;
  transition: opacity 0.5s;
}

@media (max-width: 767px) {
  .section__photos > .section__photos__images:not(:first-child) {
    //all images except the first in album
    margin: 0 15px 50px;
    padding: 0;

    height: auto;
    img {
      height: 100%;
    }
  }

  .section__photos__description {
    opacity: 1;
    font-size: $--font-xs;
    font-weight: 300;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.3);
    color: $--c-black;
    backdrop-filter: unset;
    padding: 10px;
  }

  .section__photos__preview {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);

    .section__photos__preview__image {
      justify-content: flex-end;
      object-fit: scale-down;
    }
  }

  .section__photos__images__image--mobile {
    height: 100vh;
    width: 100vw;
    margin-bottom: 20px;

    img {
      height: 100vh;
      width: 100vw;
      object-fit: cover;
    }
  }
}
