.page-not-found__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;

  span {
    font-size: 160px;
    font-weight: 100;
    opacity: 0.2;
  }

  h2 {
    margin-bottom: 20px;
  }

  a {
    color: green;
  }
}

@media only screen and (max-width: 768px) {
  .page-not-found__container {
    padding: 100px 20px;

    span {
      font-size: 100px;
    }
  }
}
