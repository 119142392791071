.album__container {
  background-color: $--c-white;
  color: $--c-black;
  min-height: 70vh;
  padding: 80px 100px 80px;
}

.album__toolbar {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 120px 0px 0px 40px;
  display: flex;
  flex-direction: column;
  background-color: $--c-white;
  width: 100%;
  z-index: 3;
  transition: all 0.3s;

  .album__title {
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 86%;

    img {
      height: 26px;
      align-self: center;
      transform: rotate(90deg);
      cursor: pointer;
    }

    h1:first-letter {
      text-transform: uppercase;
    }

    button {
      all: unset;
      cursor: pointer;
    }
  }

  .album__toolbar__thumbnail-controls {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;

    img {
      transform: rotate(0deg);
    }

    button {
      display: flex;
      padding: 12px;
      // width: auto;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.64);
      border: $--border-standard;
    }

    button:hover {
      border: $--border-focus;
    }
  }

  /* The container <div> - needed to position the dropdown content */
  .album__toolbar__thumbnail-controls__dropdown {
    transition: all 0.3s;
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .album__toolbar__thumbnail-controls__dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .album__toolbar__thumbnail-controls__dropdown-content span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  /* Change color of dropdown links on hover */
  .album__toolbar__thumbnail-controls__dropdown-content span:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
  .album__toolbar__thumbnail-controls__dropdown:hover
    .album__toolbar__thumbnail-controls__dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .album__toolbar__thumbnail-controls__dropdown:hover
    .album__toolbar__thumbnail-controls__dropbtn {
    background-color: #74ff78;
  }
}

.album__toolbar--compact {
  padding: 80px 0px 14px 40px;
}

.album__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 140px;
  justify-content: center;
}

.album__card {
  transition: all 0.3s;
  --webkit-transition: -webkit-filter 1000ms linear;
  cursor: pointer;
  animation: fadeIn 1s;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    border-radius: 4px;
  }
}

.shop__card__title {
  font-size: $--font-lg;
}

.album__card:hover {
  img {
    transform: scale(1.01);
    transition: all 0.3s;
  }
}

.album__card--small {
  height: 200px;
  width: 280px;
}

.album__card--medium {
  height: 340px;
  width: 400px;
}

.album__card--large {
  height: auto;
  width: 70vw;
  padding-bottom: 100px;

  img {
    border-radius: 6px;
  }
}

.album__card--isloading {
  opacity: 0.3;
}

.album__card__add-btn {
  position: relative;
  height: 0;

  button {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.3);
    bottom: 120px;
    right: 16px;
  }
}

.album__preview {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 50vh;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 22px 20px;
  border: $--border-standard;
  border-radius: 4px;
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 1);
  z-index: 11;
}

.album__preview__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
  z-index: 6;
  cursor: pointer;
}

.album__preview-image {
  display: flex;
  flex-direction: column;
}

.album__preview-image__picture {
  border-radius: 4px !important;
  object-fit: contain;
  height: 60vh;
  margin: 0 auto;
}

.album__preview-image__container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .album__preview__btn-close {
    position: relative;
    width: 28px;
    font-weight: 300;
    font-size: 18px;
    margin-left: auto;
    background-color: #fcfcfc;
  }

  .album__preview__btn-close:hover {
    // filter: invert(80%);
  }
}

.album__preview-image__tools {
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 10vw;
  left: 0;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  z-index: 14;

  button {
    padding: 0;
    background-color: white;
    border-radius: 6px;

    img {
      padding: 8px;
      height: 48px;
      border-radius: 4px;
    }
  }

  // button:hover {
  //   filter: invert(80%);
  // }
}

.album__preview-image__tools__arrow--prev {
  transform: rotate(90deg) !important;
}

.album__preview-image__tools__arrow--next {
  transform: rotate(270deg) !important;
}

.album__preview-image__tools__add {
  display: flex;
  align-items: center;
}

.album__preview-image__tools__price {
  position: absolute;
  width: 0px;
  margin-left: 66px;
  opacity: .8;
  font-weight: 300;
  cursor: default;
}

.album__page__controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    font-size: $--font-xxl;
    font-weight: 200;
    border: none;
  }

  img {
    width: 70px;
  }

  .album__page__controls__prev {
    position: fixed;
    top: 50%;
    left: 20px;
    transform: rotate(90deg) !important;
  }

  .album__page__controls__next {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: rotate(-90deg) !important;
  }

  .album__page__controls__prev--inactive {
    opacity: 0.2;
  }

  .album__page__controls__next--inactive {
    opacity: 0.2;
  }
}

.album__page-count {
  display: flex;
  font-size: $--font-lg;
  font-weight: 200;
  padding: 30px 0px;
  justify-content: center;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .album__container {
    padding: 10px 10px !important;

    h1 {
      font-size: $--font-lg;
    }
  }

  .album__toolbar {
    padding: 90px 0px 0px 40px;
  }

  .album__toolbar--compact {
    padding: 68px 0px 10px 40px;
  }

  .album__page__controls {
    button {
      padding: 4px 10px;
    }

    img {
      filter: invert(80%);
      width: 50px;
    }
  }

  .album__page-count {
    font-size: $--font-md;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .album__toolbar__thumbnail-controls {
    display: none !important;
  }

  .album__cards {
    width: 100%;
  }

  .album__card--small {
    height: auto;
    width: 100%;
  }

  .album__page__controls {
    button {
      padding: 4px 10px;
    }

    img {
      width: 50px;
    }

    .album__page__controls__prev {
      position: fixed;
      top: 50%;
      left: 0px;
    }

    .album__page__controls__next {
      position: fixed;
      top: 50%;
      right: 0px;
    }
  }

  .album__preview {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20vh;
    transform: none;
    padding: 6px;
    border: $--border-standard;
    width: 100%;
    height: auto;
    background-color: white;
  }

  .album__preview__background {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    backdrop-filter: blur(1px);
    z-index: 6;
    cursor: pointer;
  }

  .album__card__mobile__add-btn {
    position: relative;
    display: flex;
    justify-content: center;
    animation: fadeIn 2s;

    button {
      padding: 6px;
      width: 28px;
      margin-top: -20px;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .album__preview-image {
    img {
      height: auto;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .album__container {
    padding: 60px 40px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .shop__container {
    padding: 100px 20px;
  }
}

// @keyframes fadein {
//   0% {
//     opacity: 0;
//   }
//   66% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes fadein {
//   0% {
//     opacity: 0;
//   }
//   66% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
