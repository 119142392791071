.support__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;

  h2 {
    font-size: $--font-xl;
    padding-bottom: 10px;
    font-weight: 300;
  }

  h3 {
    font-weight: 400;
  }

  li {
    font-weight: 300;
    margin: 0 0 10px 0;
    padding: 0 0 0 10px;
  }
}

.support__main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 60px;
  width: 80%;
}

.support__contact {
  // margin: 20px;
  p {
  }
}

.support__contact__methods {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 340px;
  p {
    font-size: $--font-md;
  }

  span {
    font-size: $--font-xl;
    font-weight: 400;
  }
}

.support__contact__method {
  border: $--border-standard;
  border-radius: 6px;
  padding: 14px 20px;
  background-color: rgb(255, 254, 254);
}

.support__faq {
  padding-top: 60px;
  border-radius: 6px;

  li {
    display: flex;
    flex-direction: column;
    // align-items: left;
    padding: 30px 40px;
    border: $--border-standard;
    border-radius: 6px;

    img {
      width: 42px;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  img {
    margin-right: 6px;
  }

  .support__faq__question {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      padding: 0px 0px 0px 10px;
    }

    li:hover {
      border: none;
    }
  }
}

.support__contact__method:hover,
.support__faq li:hover {
  border: $--border-focus;
}

@media only screen and (max-width: 768px) {
  .support__container {
    padding: 100px 20px;
  }

  .support__faq {
    padding-top: 60px;
    border-radius: 6px;

    li {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .support__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 60px;
    width: 100%;
  }
}
