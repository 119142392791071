.section__about {
  background-color: $--c-white;
  color: $--c-black;
  height: auto;
  padding: 200px;

  h1 {
    font-weight: 300;
  }
}

.section__about__main {
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-bottom: 100px;

  p {
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
    margin-top: 16px;
  }

  .section__about__content--left {
    // width: 620px;
  }

  .section__about__content--right {
    // width: 1600px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

.section__about__image--main {
  position: relative;
  // width: 100%;

  img {
    outline: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin-bottom: 60px;
  }
}

.section__about__image__caption {
  display: flex;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: $--c-white;
  font-weight: 100;
  text-align: center;
  line-height: 1.3;
  padding: 8px 20px 10px;
  bottom: 0;
  width: 100%;
}

.section__about__image__caption:hover {
  // width: 100%;
}

.section__about__contact {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 20px;
  padding: 24px;

  h2 {
    font-size: 38px;
    margin-bottom: 12px;
  }
}

.section__about__contact:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s;
}

.section__about__contact__row {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-size: 24px;
    font-weight: 400;
    margin-left: 8px;
  }

  img {
    height: 30px;
  }
}

.section__about__contact__row:hover {
  transition: ease-in-out 0.3s;

  a,
  h3 {
    color: black;
  }
}

.section__about__images {
  img {
    margin-bottom: 100px;
  }
}

.section__about__image--secondary {
  border-radius: 14px;
}

.about__icons {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;

  h3 {
    margin: 0 8px 8px 0;
  }

  .about__icons__row-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .about__icons__social {
      display: flex;
      flex-direction: row;
      gap: 10px;

      img {
        height: 30px;
        filter: invert(100%);
        cursor: pointer;
      }

      .about__icon__social {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

@media (max-width: 1024px) {
  .section__about {
    padding: 10px 30px;
    width: 100vw;
  }

  .section__about__main {
    display: flex;
    flex-direction: column;
    gap: 40px;

    p {
      font-size: $--font-sm;
      font-weight: 300;
    }

    .section__about__image--main {
      img {
        border-radius: 8px;
        margin-bottom: 60px;
      }
    }

    .section__about__contact {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 14px;

      h2 {
        font-size: 28px;
        margin-bottom: 8px;
        font-weight: 200;
      }
    }

    .section__about__contact:hover {
      background-color: rgba(255, 255, 255, 0.7);
      transition: all 0.3s;
    }

    .section__about__contact__row {
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-size: 20px;
        font-weight: 500;
        margin-left: 8px;
      }

      img {
        height: 24px;
      }
    }

    .about__icons {
      margin: 20px 0 0;

      h3 {
        font-weight: 300;
        font-size: 18px;
      }
    }
  }

  .section__about__image__caption {
    display: flex;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    color: $--c-white;
    font-size: $--font-sm;
    padding: 8px 20px 10px;
    bottom: 0;
    width: 100%;
  }
}
