.privacy-policy__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;

  h3 {
    font-weight: 400;
  }

  li {
    font-weight: 300;
    margin: 0 0 10px 0;
    padding: 0 0 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .privacy-policy__container {
    padding: 100px 20px;
  }
}
