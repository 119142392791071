/* Box sizing rules */
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;500;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list],
li {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.section__welcome {
  animation: 3s ease-out 0s 1 fadeIn;
  z-index: 1;
  display: flex;
  flex-direction: row;
  animation: fadeIn 5s;
}

.cover__icons {
  position: fixed;
  bottom: 12vh;
  right: 5vw;
  z-index: 5;
}
.cover__icons .cover__icons__row-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
}
.cover__icons .cover__icons__row-container .cover__icons__social {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.cover__icons .cover__icons__row-container .cover__icons__social img {
  height: 40px;
}

.cover__image {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
}

.cover__icons__nav--left,
.cover__icons__nav--right {
  opacity: 0.7;
}

.control-arrow {
  width: 80px;
  opacity: 1 !important;
}

.control-dots li .dot {
  height: 10px;
  border: 3px solid white !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.section__photos {
  padding: 0 0 1px 0;
  margin: 0;
  background-color: rgb(242, 242, 242);
  animation: fadeIn 1s;
}

.section__photos__images img {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
}

.section__photos > .section__photos__images:first-child {
  margin-bottom: 60px;
}

.section__photos > .section__photos__images:not(:first-child) {
  margin: 0 150px 100px;
  height: auto;
  width: auto;
}
.section__photos > .section__photos__images:not(:first-child) img {
  height: 80vh;
  -o-object-fit: contain;
     object-fit: contain;
}

.section__photos__images__image--vertical {
  height: 100%;
  width: auto;
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.section__photos__description {
  opacity: 0;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 50vw;
  margin: 0 auto;
  bottom: 50px;
  text-align: center;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.4);
  color: rgb(242, 242, 242);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 20px;
}

.section__photos__images__image:hover .section__photos__description {
  opacity: 1;
  transition: opacity ease-in 0.3s;
}

.section__photos__see-more {
  position: fixed;
  bottom: 12vh;
  right: 10vw;
  opacity: 1;
}
.section__photos__see-more .section__photos__see-more__container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.section__photos__see-more .section__photos__see-more__icon img {
  height: 60px;
  transform: rotate(180deg);
}

.section__photos__see-more--hidden {
  opacity: 0;
  transition: opacity 0.5s;
}

@media (max-width: 767px) {
  .section__photos > .section__photos__images:not(:first-child) {
    margin: 0 15px 50px;
    padding: 0;
    height: auto;
  }
  .section__photos > .section__photos__images:not(:first-child) img {
    height: 100%;
  }
  .section__photos__description {
    opacity: 1;
    font-size: 10px;
    font-weight: 300;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.3);
    color: rgb(58, 57, 57);
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
    padding: 10px;
  }
  .section__photos__preview {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
  }
  .section__photos__preview .section__photos__preview__image {
    justify-content: flex-end;
    -o-object-fit: scale-down;
       object-fit: scale-down;
  }
  .section__photos__images__image--mobile {
    height: 100vh;
    width: 100vw;
    margin-bottom: 20px;
  }
  .section__photos__images__image--mobile img {
    height: 100vh;
    width: 100vw;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.section__about {
  background-color: rgb(242, 242, 242);
  color: rgb(58, 57, 57);
  height: auto;
  padding: 200px;
}
.section__about h1 {
  font-weight: 300;
}

.section__about__main {
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-bottom: 100px;
}
.section__about__main p {
  font-size: 16px;
  font-weight: 300;
  text-align: justify;
  margin-top: 16px;
}
.section__about__main .section__about__content--right {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.section__about__image--main {
  position: relative;
}
.section__about__image--main img {
  outline: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  margin-bottom: 60px;
}

.section__about__image__caption {
  display: flex;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: rgb(242, 242, 242);
  font-weight: 100;
  text-align: center;
  line-height: 1.3;
  padding: 8px 20px 10px;
  bottom: 0;
  width: 100%;
}

.section__about__contact {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 20px;
  padding: 24px;
}
.section__about__contact h2 {
  font-size: 38px;
  margin-bottom: 12px;
}

.section__about__contact:hover {
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s;
}

.section__about__contact__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section__about__contact__row h3 {
  font-size: 24px;
  font-weight: 400;
  margin-left: 8px;
}
.section__about__contact__row img {
  height: 30px;
}

.section__about__contact__row:hover {
  transition: ease-in-out 0.3s;
}
.section__about__contact__row:hover a,
.section__about__contact__row:hover h3 {
  color: black;
}

.section__about__images img {
  margin-bottom: 100px;
}

.section__about__image--secondary {
  border-radius: 14px;
}

.about__icons {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}
.about__icons h3 {
  margin: 0 8px 8px 0;
}
.about__icons .about__icons__row-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.about__icons .about__icons__row-container .about__icons__social {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.about__icons .about__icons__row-container .about__icons__social img {
  height: 30px;
  filter: invert(100%);
  cursor: pointer;
}
.about__icons .about__icons__row-container .about__icons__social .about__icon__social {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .section__about {
    padding: 10px 30px;
    width: 100vw;
  }
  .section__about__main {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .section__about__main p {
    font-size: 14px;
    font-weight: 300;
  }
  .section__about__main .section__about__image--main img {
    border-radius: 8px;
    margin-bottom: 60px;
  }
  .section__about__main .section__about__contact {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 14px;
  }
  .section__about__main .section__about__contact h2 {
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: 200;
  }
  .section__about__main .section__about__contact:hover {
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s;
  }
  .section__about__main .section__about__contact__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .section__about__main .section__about__contact__row h3 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 8px;
  }
  .section__about__main .section__about__contact__row img {
    height: 24px;
  }
  .section__about__main .about__icons {
    margin: 20px 0 0;
  }
  .section__about__main .about__icons h3 {
    font-weight: 300;
    font-size: 18px;
  }
  .section__about__image__caption {
    display: flex;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    color: rgb(242, 242, 242);
    font-size: 14px;
    padding: 8px 20px 10px;
    bottom: 0;
    width: 100%;
  }
}
.shop__container {
  background-color: rgb(252, 252, 252);
  color: rgb(58, 57, 57);
  min-height: 90vh;
  padding: 140px 100px;
  animation: fadeIn 1s;
}

.shop__steps__cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  padding: 40px 40px 40px 0;
  margin-bottom: 20px;
  justify-content: space-between;
  border-radius: 4px;
  cursor: default;
}

.shop__steps__card {
  position: relative;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 30%;
  background-color: rgb(240, 255, 241);
  border-radius: 4px;
  gap: 10px;
  align-items: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  animation: fadeIn 2s;
  transition: all 0.3s;
}
.shop__steps__card span {
  font-size: 50px;
  font-weight: 100;
}
.shop__steps__card p {
  font-size: 20px;
}
.shop__steps__card .shop__steps__icon {
  position: absolute;
  height: 60px;
  width: 60px;
  opacity: 0.16;
}
.shop__steps__card .shop__steps__icon--eye {
  top: -40px;
  right: 30px;
}
.shop__steps__card .shop__steps__icon--cart {
  bottom: -30px;
  right: 30px;
}
.shop__steps__card .shop__steps__icon--photo {
  top: -40px;
  right: 30px;
}

.shop__steps__card:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgb(199, 255, 203);
}

.shop__steps__card:hover .shop__steps__icon {
  opacity: 0.9;
}

.shop__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  margin-top: 50px;
  justify-content: center;
  animation: fadeIn 2s;
}
.shop__cards ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px 0px;
}
.shop__cards .shop__cards__background-image {
  position: fixed;
  opacity: 0.03;
  bottom: 100px;
  right: 100px;
}
.shop__cards .shop__cards__background-image img {
  animation: fadeIn 3s;
}
.shop__cards .shop__cards__background-image--large {
  left: 0px;
  bottom: 0px;
}

.shop__card {
  height: auto;
  opacity: 0.9;
}
.shop__card img {
  width: 500px;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
}

.shop__card__title {
  height: auto;
  word-wrap: break-word;
}

.shop__card:hover {
  opacity: 1;
  transition: all 1s;
}
.shop__card:hover img {
  transform: scale(1.025);
  transition: ease-in-out 0.3s;
}

/* Small small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .shop__container {
    padding: 80px 20px !important;
  }
  .shop__steps__card {
    padding: 10px 14px;
  }
  .shop__steps__card p {
    font-size: 14px;
  }
  .shop__steps__card span {
    font-size: 30px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .shop__steps__cards .shop__steps__icon {
    display: none;
  }
  .shop__card {
    padding: 10px;
  }
  .shop__cards .shop__cards__background-image {
    display: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .shop__steps__cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
  }
  .shop__steps__card {
    width: 100%;
    height: auto;
  }
  .shop__steps__icon {
    position: relative;
    height: 60px;
    width: 60px;
    opacity: 0.16;
    justify-content: flex-end;
  }
  .shop__steps__icon--eye {
    top: 26px !important;
    right: 30px !important;
  }
  .shop__steps__icon--cart {
    top: 26px !important;
    right: 30px !important;
  }
  .shop__steps__icon--photo {
    top: 26px !important;
    right: 30px !important;
  }
}
.album__container {
  background-color: rgb(242, 242, 242);
  color: rgb(58, 57, 57);
  min-height: 70vh;
  padding: 80px 100px 80px;
}

.album__toolbar {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 120px 0px 0px 40px;
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 242, 242);
  width: 100%;
  z-index: 3;
  transition: all 0.3s;
  /* The container <div> - needed to position the dropdown content */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* Change color of dropdown links on hover */
  /* Show the dropdown menu on hover */
  /* Change the background color of the dropdown button when the dropdown content is shown */
}
.album__toolbar .album__title {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 86%;
}
.album__toolbar .album__title img {
  height: 26px;
  align-self: center;
  transform: rotate(90deg);
  cursor: pointer;
}
.album__toolbar .album__title h1:first-letter {
  text-transform: uppercase;
}
.album__toolbar .album__title button {
  all: unset;
  cursor: pointer;
}
.album__toolbar .album__toolbar__thumbnail-controls {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
}
.album__toolbar .album__toolbar__thumbnail-controls img {
  transform: rotate(0deg);
}
.album__toolbar .album__toolbar__thumbnail-controls button {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.64);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.album__toolbar .album__toolbar__thumbnail-controls button:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown {
  transition: all 0.3s;
  position: relative;
  display: inline-block;
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown-content span:hover {
  background-color: #ddd;
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown:hover .album__toolbar__thumbnail-controls__dropdown-content {
  display: block;
}
.album__toolbar .album__toolbar__thumbnail-controls__dropdown:hover .album__toolbar__thumbnail-controls__dropbtn {
  background-color: #74ff78;
}

.album__toolbar--compact {
  padding: 80px 0px 14px 40px;
}

.album__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 140px;
  justify-content: center;
}

.album__card {
  transition: all 0.3s;
  --webkit-transition: -webkit-filter 1000ms linear;
  cursor: pointer;
  animation: fadeIn 1s;
}
.album__card img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 4px;
}

.shop__card__title {
  font-size: 20px;
}

.album__card:hover img {
  transform: scale(1.01);
  transition: all 0.3s;
}

.album__card--small {
  height: 200px;
  width: 280px;
}

.album__card--medium {
  height: 340px;
  width: 400px;
}

.album__card--large {
  height: auto;
  width: 70vw;
  padding-bottom: 100px;
}
.album__card--large img {
  border-radius: 6px;
}

.album__card--isloading {
  opacity: 0.3;
}

.album__card__add-btn {
  position: relative;
  height: 0;
}
.album__card__add-btn button {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 120px;
  right: 16px;
}

.album__preview {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 50vh;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 22px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: auto;
  height: auto;
  background-color: rgb(255, 255, 255);
  z-index: 11;
}

.album__preview__background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  z-index: 6;
  cursor: pointer;
}

.album__preview-image {
  display: flex;
  flex-direction: column;
}

.album__preview-image__picture {
  border-radius: 4px !important;
  -o-object-fit: contain;
     object-fit: contain;
  height: 60vh;
  margin: 0 auto;
}

.album__preview-image__container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.album__preview-image__container .album__preview__btn-close {
  position: relative;
  width: 28px;
  font-weight: 300;
  font-size: 18px;
  margin-left: auto;
  background-color: #fcfcfc;
}
.album__preview-image__tools {
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 10vw;
  left: 0;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  z-index: 14;
}
.album__preview-image__tools button {
  padding: 0;
  background-color: white;
  border-radius: 6px;
}
.album__preview-image__tools button img {
  padding: 8px;
  height: 48px;
  border-radius: 4px;
}

.album__preview-image__tools__arrow--prev {
  transform: rotate(90deg) !important;
}

.album__preview-image__tools__arrow--next {
  transform: rotate(270deg) !important;
}

.album__preview-image__tools__add {
  display: flex;
  align-items: center;
}

.album__preview-image__tools__price {
  position: absolute;
  width: 0px;
  margin-left: 66px;
  opacity: 0.8;
  font-weight: 300;
  cursor: default;
}

.album__page__controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.album__page__controls button {
  font-size: 36px;
  font-weight: 200;
  border: none;
}
.album__page__controls img {
  width: 70px;
}
.album__page__controls .album__page__controls__prev {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: rotate(90deg) !important;
}
.album__page__controls .album__page__controls__next {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: rotate(-90deg) !important;
}
.album__page__controls .album__page__controls__prev--inactive {
  opacity: 0.2;
}
.album__page__controls .album__page__controls__next--inactive {
  opacity: 0.2;
}

.album__page-count {
  display: flex;
  font-size: 20px;
  font-weight: 200;
  padding: 30px 0px;
  justify-content: center;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .album__container {
    padding: 10px 10px !important;
  }
  .album__container h1 {
    font-size: 20px;
  }
  .album__toolbar {
    padding: 90px 0px 0px 40px;
  }
  .album__toolbar--compact {
    padding: 68px 0px 10px 40px;
  }
  .album__page__controls button {
    padding: 4px 10px;
  }
  .album__page__controls img {
    filter: invert(80%);
    width: 50px;
  }
  .album__page-count {
    font-size: 16px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .album__toolbar__thumbnail-controls {
    display: none !important;
  }
  .album__cards {
    width: 100%;
  }
  .album__card--small {
    height: auto;
    width: 100%;
  }
  .album__page__controls button {
    padding: 4px 10px;
  }
  .album__page__controls img {
    width: 50px;
  }
  .album__page__controls .album__page__controls__prev {
    position: fixed;
    top: 50%;
    left: 0px;
  }
  .album__page__controls .album__page__controls__next {
    position: fixed;
    top: 50%;
    right: 0px;
  }
  .album__preview {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20vh;
    transform: none;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: auto;
    background-color: white;
  }
  .album__preview__background {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    z-index: 6;
    cursor: pointer;
  }
  .album__card__mobile__add-btn {
    position: relative;
    display: flex;
    justify-content: center;
    animation: fadeIn 2s;
  }
  .album__card__mobile__add-btn button {
    padding: 6px;
    width: 28px;
    margin-top: -20px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .album__preview-image img {
    height: auto;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .album__container {
    padding: 60px 40px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .shop__container {
    padding: 100px 20px;
  }
}
.cart__container {
  padding: 180px 100px 200px;
  background-color: rgb(242, 242, 242);
  font-weight: 300;
  min-height: 70vh;
}

.cart__return {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 12px;
}
.cart__return h1 {
  font-weight: 100;
}

.cart__sections {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.cart__images {
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  padding: 30px;
  gap: 20px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  transition: all 0.3s;
}
.cart__images .cart__image__labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 0 0 20px;
  text-align: center;
}
.cart__images .cart__image__labels .cart__image__labels--photo {
  width: 200px;
}
.cart__images .cart__image__labels .cart__image__labels--preview {
  flex: 1;
}
.cart__images .cart__image__labels .cart__image__labels--price {
  flex: 1;
}
.cart__images .cart__image__labels .cart__image__labels--options {
  flex: 1;
}
.cart__images .cart__image {
  display: flex;
}
.cart__images .cart__image__tools--image {
  width: 200px;
  border-radius: 4px;
}
.cart__images .cart__image__tools--preview {
  width: 24px;
}
.cart__images .cart__image__tools {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.cart__summary {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  gap: 20px;
  width: 300px;
  height: 300px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}
.cart__summary .cart__summary__price {
  font-size: 16px;
}
.cart__summary .cart__summary__button {
  width: 220px;
}

.cart__images:hover,
.cart__summary:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  opacity: 0;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.3s;
  font-size: 14px;
  position: absolute;
  top: 20px;
  left: -60px;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  opacity: 1;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (max-width: 600px) {
  .cart__container .cart__image {
    display: flex;
    flex-direction: column;
  }
  .cart__container .cart__image__tools {
    margin-top: 6px;
    justify-content: space-between;
  }
  .cart__container .cart__image__tools--image {
    width: auto;
    border-radius: 4px;
  }
  .cart__container .cart__image__labels {
    display: none;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .cart__image__labels--preview {
    display: none;
  }
  .cart__images .cart__image__tools--preview {
    display: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .cart__container {
    padding: 100px 40px 100px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .cart__sections {
    display: flex;
    flex-direction: column;
  }
}
.signin__container {
  background-color: rgba(255, 255, 255, 0.85);
  color: rgb(58, 57, 57);
  padding: 180px 100px 200px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
}
.signin__container h1 {
  font-weight: 400;
}
.signin__container h2 {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0 26px;
}

.signin__container--create-account .signin__form {
  background-color: rgba(253, 255, 230, 0.85);
}

.signin__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;
  padding: 20px 0;
  width: 400px;
  border: 1px solid rgba(0, 255, 85, 0.15);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.5);
}
.signin__form label {
  padding: 0 0 2px 16px;
  font-size: 16px;
}
.signin__form input {
  all: unset;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 16px;
  width: 300px;
}
.signin__form ::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}
.signin__form .signin__form__group {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.signin__form button {
  all: unset;
  padding: 6px 26px;
  margin-top: 22px;
  background-color: rgba(165, 254, 183, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  transition: 0.2s;
}
.signin__form button:hover {
  background-color: rgb(70, 255, 107);
}

.signin__form__create-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.signin__form__create-account button {
  all: unset;
  padding: 6px 26px;
  margin-top: 22px;
  background-color: rgba(165, 250, 254, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

.checkout__container {
  background-color: rgb(242, 242, 242);
  color: rgb(58, 57, 57);
  min-height: 70vh;
  padding: 180px 100px 200px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
}

.checkout__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0 0;
}
.checkout__items .checkout__items__image {
  max-width: 200px;
  align-self: center;
}
.checkout__items .checkout__items__image img {
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 120px;
  border-radius: 4px;
}

.checkout__summary {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  margin: 0 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s;
}

.checkout__summary:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.checkout__summary__cart {
  padding: 20px;
  border-radius: 6px;
}

.checkout__total {
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 200px;
  padding: 14px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.checkout__total span {
  font-size: 16px;
}
.checkout__total .checkout__total__price {
  font-size: 20px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .checkout__container {
    padding: 120px 20px 100px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
.purchased__container {
  background-color: rgba(255, 255, 255, 0.85);
  color: rgb(58, 57, 57);
  min-height: 70vh;
  padding: 180px 100px 160px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 14px;
}
.purchased__container img {
  pointer-events: auto;
}

.purchased__image__card {
  margin: 20px 0 40px;
  display: flex;
  flex-direction: column;
}
.purchased__image__card .purchased__image__card__img {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}
.purchased__image__card .purchased__image__card__img:hover {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.purchased__image__card .purchased__image__card__tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 0;
}

.purchased__error__container {
  padding: 30px 0 0;
}
.purchased__error__container h1 {
  font-size: 36px;
}
.purchased__error__container p {
  font-size: 16px;
}
.purchased__error__container i {
  font-weight: 400;
}

.purchased__error__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.purchased__icon {
  width: 50px;
}

.purchased__icon--question {
  width: 30px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .purchased__container {
    padding: 180px 80px 100px;
  }
  .purchased__container .purchased__image__card__tools {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .purchased__container {
    padding: 100px 20px 60px;
  }
}
.support__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;
}
.support__container h2 {
  font-size: 24px;
  padding-bottom: 10px;
  font-weight: 300;
}
.support__container h3 {
  font-weight: 400;
}
.support__container li {
  font-weight: 300;
  margin: 0 0 10px 0;
  padding: 0 0 0 10px;
}

.support__main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 60px;
  width: 80%;
}

.support__contact__methods {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 340px;
}
.support__contact__methods p {
  font-size: 16px;
}
.support__contact__methods span {
  font-size: 24px;
  font-weight: 400;
}

.support__contact__method {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 14px 20px;
  background-color: rgb(255, 254, 254);
}

.support__faq {
  padding-top: 60px;
  border-radius: 6px;
}
.support__faq li {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.support__faq li img {
  width: 42px;
}
.support__faq li div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.support__faq img {
  margin-right: 6px;
}
.support__faq .support__faq__question li {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0px 0px 0px 10px;
}
.support__faq .support__faq__question li:hover {
  border: none;
}

.support__contact__method:hover,
.support__faq li:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
  .support__container {
    padding: 100px 20px;
  }
  .support__faq {
    padding-top: 60px;
    border-radius: 6px;
  }
  .support__faq li {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .support__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 60px;
    width: 100%;
  }
}
.terms__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;
}
.terms__container h3 {
  font-weight: 400;
}
.terms__container li {
  font-weight: 300;
  margin: 0 0 10px 0;
  padding: 0 0 0 10px;
}

@media only screen and (max-width: 768px) {
  .terms__container {
    padding: 100px 20px;
  }
}
.privacy-policy__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;
}
.privacy-policy__container h3 {
  font-weight: 400;
}
.privacy-policy__container li {
  font-weight: 300;
  margin: 0 0 10px 0;
  padding: 0 0 0 10px;
}

@media only screen and (max-width: 768px) {
  .privacy-policy__container {
    padding: 100px 20px;
  }
}
.page-not-found__container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 180px 100px 200px;
  height: auto;
}
.page-not-found__container span {
  font-size: 160px;
  font-weight: 100;
  opacity: 0.2;
}
.page-not-found__container h2 {
  margin-bottom: 20px;
}
.page-not-found__container a {
  color: green;
}

@media only screen and (max-width: 768px) {
  .page-not-found__container {
    padding: 100px 20px;
  }
  .page-not-found__container span {
    font-size: 100px;
  }
}
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 60px 100px;
  width: 100%;
  color: rgb(242, 242, 242);
  z-index: 10;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.9) 100%);
  transition: all 0.3s;
}
.navbar .navbar__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 24px;
}
.navbar .navbar__logo {
  width: 200px;
  display: flex;
  align-items: center;
}
.navbar .navbar__section-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
.navbar .navbar__section-link a {
  display: flex;
  justify-content: center;
  color: rgb(242, 242, 242);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 200;
}
.navbar .navbar__section-link a:hover {
  color: rgb(200, 200, 200);
  transition: 0.3s ease-in-out;
  text-shadow: 0px 0px 1px rgb(0, 0, 0);
}
.navbar .navbar__section-link__shop {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 3px 30px;
}
.navbar .navbar__shop-icons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  height: 40px;
  cursor: pointer;
  gap: 10px;
}

.navbar--solid {
  padding: 16px 60px;
  background-color: rgba(20, 20, 20, 0.9);
}
.navbar--solid .navbar__logo {
  width: 140px;
}
.navbar--solid .navbar__content {
  font-size: 16px;
}

.navbar__menu__button {
  all: unset;
  display: none;
  justify-content: center;
  position: fixed;
  left: 12vw;
  top: 4vh;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
  z-index: 5;
}

.navbar__menu__button--open img {
  height: 50px;
}

.navbar__menu__button--close {
  top: 5vh;
}
.navbar__menu__button--close img {
  height: 40px;
}

.navbar--show {
  display: flex !important;
}

.mobile__logo {
  display: none;
}

.navbar--invert {
  filter: invert(100%);
}

.navbar__shop {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 50px;
  z-index: 10;
  transition: linear 0.3s;
  animation: fadeIn 0.3s;
}

.navbar__shop__logo {
  display: flex;
  width: 140px;
  filter: invert(100%);
  align-items: center;
  justify-content: center;
}

.navbar__shop__content {
  transition: all 0.3s;
}

.navbar__shop__header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 300;
  margin-left: 16px;
}

.navbar__shop__userinfo {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  gap: 10px;
  font-weight: 300;
}
.navbar__shop__userinfo span {
  text-transform: capitalize;
}
.navbar__shop__userinfo .navbar__shop__language {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  gap: 6px;
}
.navbar__shop__userinfo .navbar__shop__language button {
  all: unset;
}
.navbar__shop__userinfo .navbar__shop__language img {
  width: 20px;
}
.navbar__shop__userinfo .navbar__shop__language .navbar__shop__language__content {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  width: 60px;
  height: auto;
  top: 8px;
  left: -18px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
}
.navbar__shop__userinfo .navbar__shop__language .navbar__shop__language__content li {
  line-height: 200%;
  width: 100%;
  padding: 4px 0px 4px 18px;
}
.navbar__shop__userinfo .navbar__shop__language .navbar__shop__language__content li:hover {
  background-color: #74ff78;
}
.navbar__shop__userinfo .navbar__shop__language:hover .navbar__shop__language__content {
  display: flex;
}

.navbar__shop__userinfo--main-page img {
  filter: invert(90%);
}
.navbar__shop__userinfo--main-page li {
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar__shop__icons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 10px;
  align-items: center;
}
.navbar__shop__icons img {
  width: 36px;
  cursor: pointer;
}
.navbar__shop__icons .navbar__shop__icon__cart {
  position: relative;
}
.navbar__shop__icons .navbar__shop__icon__cart .navbar__shop__icon__cart__count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 14px;
  width: 22px;
  height: 22px;
  border-radius: 100px;
  padding: 4px;
  right: -10px;
  top: -10px;
}

.navbar__shop--shrink {
  padding: 10px 40px 10px 20px;
  background-color: rgba(255, 255, 255, 0.8);
}
.navbar__shop--shrink .navbar__shop__logo img {
  width: 100px;
}
.navbar__shop--shrink .navbar__content {
  font-size: 16px;
  transition: all 0.3s;
}
.navbar__shop--shrink .navbar__shop__icons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 10px;
  align-items: center;
}
.navbar__shop--shrink .navbar__shop__icons img {
  cursor: pointer;
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1024px) {
  .navbar {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    height: 100vh;
    width: 100%;
    padding: 0;
    transition: 0.3s;
  }
  .navbar .navbar__logo {
    display: none;
  }
  .navbar .navbar__content {
    flex-direction: column;
    gap: 4px;
    margin-top: -10vh;
    width: 100vw;
  }
  .navbar .navbar__content .navbar__section-link {
    width: 100vw;
    font-size: 24px;
  }
  .navbar .navbar__content .navbar__section-link a {
    width: 100%;
    padding: 12px;
    border: none;
  }
  .navbar .navbar__content .navbar__section-link a:hover {
    background-color: black;
  }
  .navbar .navbar__shop__userinfo {
    padding: 16px;
    font-size: 20px;
    justify-content: center;
    width: 100vw;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  }
  .navbar__menu__button {
    display: flex;
  }
  .mobile__logo {
    position: fixed;
    display: flex;
    right: 12vw;
    top: 4.4vh;
    width: 100px;
    transition: 0.5s ease-in-out;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
    z-index: 5;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .navbar {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%);
  }
  .navbar__shop {
    padding: 10px 40px 10px 40px;
  }
  .navbar__shop__logo {
    width: 100px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .navbar__shop__header {
    font-size: 20px;
    font-weight: 300;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .navbar__shop {
    padding: 12px 20px;
  }
  .navbar__shop--shrink {
    padding: 6px 20px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .navbar__shop--shrink .navbar__shop__logo {
    width: 100px;
  }
  .navbar__shop--shrink .navbar__content {
    font-size: 16px;
  }
}
.footer {
  position: relative;
  height: auto;
  width: 100vw;
  padding: 60px 200px;
  background-color: rgb(40, 40, 40);
  display: flex;
  flex-direction: column;
  color: rgb(242, 242, 242);
  font-size: 14px;
  line-height: 30px;
  overflow: hidden;
  font-weight: 100;
  z-index: 2 !important;
}
.footer a {
  color: rgb(242, 242, 242);
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 6%;
  z-index: 2;
}

.footer__logo {
  width: 180px;
}

.footer__column {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.footer__tags span {
  opacity: 0.6;
}
.footer__tags a {
  color: rgb(242, 242, 242);
}

.footer__return {
  margin-left: auto;
  cursor: pointer;
}

.footer__social {
  display: flex;
  gap: 10px;
  margin: 26px 0;
  height: 40px;
  align-items: center;
}
.footer__social img {
  width: 30px;
}

.footer__slogan {
  position: absolute;
  opacity: 0.1;
  font-size: 5vw;
  margin-top: 100px;
  right: 20%;
  font-weight: 100;
  z-index: 1;
}

.footer__copyright {
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 200;
  width: 100%;
  opacity: 1;
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3 !important;
}
.footer__copyright a {
  transition: all 0.3s;
  color: rgba(231, 182, 255, 0.7);
}
.footer__copyright a:hover {
  color: rgb(0, 255, 155);
}

@media (max-width: 1024px) {
  .footer {
    padding: 20px 24px 80px;
  }
  .footer__logo {
    padding: 20px 0;
  }
  .footer__links {
    align-items: center;
  }
  .footer__slogan {
    display: none;
  }
}
@media (max-width: 768px) {
  .footer__content {
    display: flex;
    flex-direction: column-reverse;
    gap: 6%;
  }
  .footer__column {
    margin: 0 auto;
  }
  .footer__tags {
    display: none;
  }
  .footer__return {
    position: absolute;
    right: 36px;
    top: 20px;
  }
  .footer__copyright {
    flex-direction: column;
    align-items: center;
    font-size: 10px;
  }
}
.order-form__container {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  max-height: auto;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.order-form__container:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.order-form__content {
  margin: 10px 0 0;
}
.order-form__content form {
  display: flex;
  flex-direction: column;
}
.order-form__content label {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
}
.order-form__content input {
  width: 200px;
}
.order-form__content .order-form__content__group {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.order-form__content .order-form__content__checkbox {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.order-form__content .order-form__content__checkbox label {
  display: block;
  width: 95%;
}
.order-form__content .order-form__content__checkbox label a {
  color: rgb(200, 0, 0);
}
.order-form__content .order-form__content__checkbox input {
  width: 200px;
}
.order-form__content .order-form__content__checkbox input[type=checkbox] {
  width: 16px;
}

.order-form__content--error {
  background-color: pink;
  border: 1px solid grey;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  form .btn--primary {
    width: auto;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .order-form__content .order-form__content__group {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .order-form__content__checkbox input[type=checkbox] {
    width: 24px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
.alert__container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16vh;
  right: 6vw;
  font-size: 16px;
  font-weight: 300;
  width: auto;
  padding: 20px 50px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 6px;
  z-index: 12;
  transition: all 0.3s;
}

.alert__container--standard {
  position: absolute;
  top: 20vh;
  left: 10vw;
}

.alert__container--red {
  background: rgba(255, 190, 190, 0.8);
  border: 1px solid rgb(135, 135, 135);
}

.alert__container--green {
  background: rgba(150, 255, 134, 0.86);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.alert__autohide {
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    border: 0;
    opacity: 0;
  }
}
.loading-spinner__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-spinner div {
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(58, 57, 57);
  margin: -4px 0 0 -4px;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.036s;
}

.loading-spinner div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.072s;
}

.loading-spinner div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.108s;
}

.loading-spinner div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.loading-spinner div:nth-child(4) {
  animation-delay: -0.144s;
}

.loading-spinner div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.loading-spinner div:nth-child(5) {
  animation-delay: -0.18s;
}

.loading-spinner div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.loading-spinner div:nth-child(6) {
  animation-delay: -0.216s;
}

.loading-spinner div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.loading-spinner div:nth-child(7) {
  animation-delay: -0.252s;
}

.loading-spinner div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.loading-spinner div:nth-child(8) {
  animation-delay: -0.288s;
}

.loading-spinner div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cookies__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0px;
  bottom: 0px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  height: auto;
  margin: 4px;
  padding: 14px 26px;
  border-radius: 6px;
  font-weight: 100;
  gap: 14px;
  font-size: 14px;
  z-index: 5;
}
.cookies__container span {
  text-align: justify;
  text-justify: inter-word;
  font-weight: 100;
}

.cookies__content {
  display: flex;
  flex-direction: row;
  justify-items: space-around;
  gap: 10px;
}
.cookies__content button {
  padding: 6px;
  width: 160px;
  height: 22px;
  font-size: 14px;
  font-weight: 200;
  outline: 1px solid rgba(0, 0, 0, 0.5);
}
.cookies__content button:hover {
  outline: rgb(46, 46, 46) !important;
}
.cookies__content .cookies__button--more {
  height: 22px;
  background-color: rgba(255, 255, 255, 0.9);
}
.cookies__content .cookies__button--all:hover {
  color: black;
}
.cookies__content .cookies__button--decline {
  background-color: rgba(122, 122, 122, 0.3) !important;
}

.cookies-popup__close {
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .cookies__container {
    width: 100%;
    margin: 0px;
    border-radius: 0;
  }
  .cookies__container button {
    width: 100%;
  }
  .cookies__content {
    flex-direction: column;
  }
}
html {
  overflow-x: hidden;
}

body {
  font-family: "Prompt", sans-serif;
  color: rgb(58, 57, 57);
  background-color: rgb(242, 242, 242);
  overflow: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-weight: 300;
}

h1 {
  font-weight: 200;
}

h2 {
  font-size: 24px;
  font-weight: 300;
}

p {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: rgb(58, 57, 57);
}

ul {
  padding: 0;
}

img {
  pointer-events: none;
}

button {
  all: unset;
  text-align: center !important;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.btn--primary {
  background-color: rgba(0, 222, 0, 0.7) !important;
  width: 300px;
}

.btn--primary:hover {
  background-color: rgba(0, 255, 0, 0.9) !important;
}

.btn--secondary {
  width: 300px;
}

.btn--secondary:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.btn--tertiary {
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(250, 250, 250);
  border-radius: 4px;
}

.btn--tertiary:hover {
  opacity: 0.8;
}

.btn--back {
  border: 0px;
  padding: 0;
  cursor: pointer !important;
  display: flex;
  flex-direction: row;
}
.btn--back img {
  border: 0px;
  min-width: 30px;
  align-self: center;
  transform: rotate(90deg);
  cursor: pointer;
}

.hidden {
  display: none;
}

.form__error-message {
  color: red;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgb(58, 57, 57);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(242, 242, 242);
  border-radius: 10px;
  border: 1px solid slategray;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
    font-weight: 300;
  }
  p {
    font-size: 16px;
  }
  a {
    text-decoration: none;
    color: rgb(58, 57, 57);
  }
  ul {
    padding: 0;
  }
  .btn--primary, .btn--secondary, .btn--tertiary {
    width: auto;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) *//*# sourceMappingURL=style.css.map */